import {List, Toast, Grid, WhiteSpace} from 'antd-mobile';
import {createForm} from 'rc-form';
import * as React from "react";
import {Route} from "react-router-dom";
import API from "./api";
import "./Stats.css"

class Stats extends React.Component {
  state = {
    monthlyReports: [],
    loading: false,
  }

  api = new API();

  componentDidMount() {
    const token = localStorage.getItem("token");
    this.api.getMonths(token).then(r2 => {
      if (r2.code === 200) {
        const monthlyReports = r2.data.map((month) => {
          return {
            icon: 'https://gw.alipayobjects.com/zos/rmsportal/nywPmnTAvTmLusPxHPSu.png',
            text: `${month}`,
          };
        });
        this.setState({
          monthlyReports,
        });
      }
    });
  }

  onMonthlyReportsClick = (gridItem, history) => {
    const month = gridItem.text;
    const token = localStorage.getItem("token");
    if (!token) {
      history.push('/login');
      return;
    }

    this.setState({
      loading: true,
    });

    Toast.loading('下载中...', 0, () => {
      console.log('Load complete !!!');
    }, true);

    this.api.getPerfReports(token, month).then(response => {
      this.setState({
        loading: false,
      });
      Toast.hide();
      if (response.code === 200) {
        window.location.href = `${response.data.uri}`;
      } else if (response.code === 401000) {
        localStorage.removeItem("token");
        history.push('/login');
        return;
      } else if (response.code === 403001) {
        Toast.info('没有权限做此操作，请联系管理员开通权限');
      } else if (response.code === 400510) {
        Toast.info(`数据尚为填充完整: ${response.debugMessage}`);
      } else if (response.code === 400508) {
        Toast.info(`EXCEL导出错误: ${response.debugMessage}`);
      } else {
        const msg = `系统错误: ${response.code}: ${response.debugMessage}`
        Toast.info(msg);
      }
    });
  }

  render() {
    const {getFieldProps} = this.props.form;
    const monthlyReports = this.state.monthlyReports;
    const loading = this.state.loading;
    const monthlyReportsView = monthlyReports.length > 0
      ? <Route render={({history}) => (
        <Grid data={monthlyReports} activeStyle={!loading} onClick={_el => this.onMonthlyReportsClick(_el, history)}/>)}/> :
      <div className="no-reports">暂无报表</div>;
    return (
      <div>
        <div style={{
          width: '100%',
          'font-size': '1.5em',
          'margin-top': '2rem',
          'margin-bottom': '2rem',
          'margin-left': '1rem',
          textAlign: 'left'
        }}>统计分析
        </div>
        <List>
          <List.Item>
            <Route render={({history}) => (
              <div>
                <div className="sub-title">月报表</div>
                {monthlyReportsView}
              </div>
            )}/>
          </List.Item>

          <WhiteSpace/>

        </List>
      </div>
    );
  }
}

export default createForm()(Stats);
