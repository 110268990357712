import jQuery from 'jquery';

class API {

  async register(data = {}) {
    const response = await fetch(`${base}/api/user/register`, {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data)
    });
    return response.json();
  }

  async login(data = {}) {
    const response = await fetch(`${base}/api/user/login`, {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data)
    });
    return response.json();
  }

  validateAuth(token) {
    const responseText = jQuery.ajax({
      type: "GET",
      url: `${base}/api/user/auth`,
      headers: {
        'Content-Type': 'application/json',
        'x-jooz-authorization': token,
      },
      cache: false,
      async: false
    }).responseText;
    return JSON.parse(responseText);
  }

  async addChannelRecovery(token, data = {}) {
    const response = await fetch(`${base}/api/recovery/channel`, {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json',
        'x-jooz-authorization': token,
      },
      body: JSON.stringify(data)
    });
    return response.json();
  }

  async addYizhiliaoRecovery(token, data = {}) {
    const response = await fetch(`${base}/api/recovery/yizhiliao`, {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json',
        'x-jooz-authorization': token,
      },
      body: JSON.stringify(data)
    });
    return response.json();
  }

  async getPerfReports(token, month) {
    const response = await fetch(`${base}/api/stats/reports/perf?month=${month}`, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'x-jooz-authorization': token,
      },
    });
    return response.json();
  }

  async findChannelRecovery(token) {
    const response = await fetch(`${base}/api/recovery/channel?size=200`, {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json',
        'x-jooz-authorization': token,
      },
    });
    return response.json();
  }

  async findYizhiliaoRecovery(token) {
    const response = await fetch(`${base}/api/recovery/yizhiliao?size=200`, {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json',
        'x-jooz-authorization': token,
      },
    });
    return response.json();
  }

  async getMonths(token) {
    const response = await fetch(`${base}/api/stats/reports/months`, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'x-jooz-authorization': token,
      },
    });
    return response.json();
  }

  async getAllRegionProducts(token) {
    const response = await fetch(`${base}/api/config/getAllRegionProducts`, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'x-jooz-authorization': token,
      },
    });
    return response.json();
  }

  async getAllAgents(token) {
    const response = await fetch(`${base}/api/config/getAllAgents`, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'x-jooz-authorization': token,
      },
    });
    return response.json();
  }

  async getAllChannels(token) {
    const response = await fetch(`${base}/api/config/getAllChannels`, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'x-jooz-authorization': token,
      },
    });
    return response.json();
  }

}

const base = getBase();

// https://create-react-app.dev/docs/adding-custom-environment-variables
function getBase() {
  let baseHost;
  switch (process.env.NODE_ENV) {
    case 'development':
    case 'test':
      baseHost = 'http://localhost:8080';
      break;
    case 'production':
      baseHost = 'https://api.pepaya.vip';
      // baseHost = 'http://localhost:8080';
      break;
    default:
      baseHost = 'http://localhost:8080';
  }
  return baseHost;
}

export default API;
