import {
  List,
  InputItem,
  DatePicker,
  Picker,
  TextareaItem,
  Toast,
  Card,
  WingBlank,
  WhiteSpace
} from 'antd-mobile';
import {createForm} from 'rc-form';
import * as React from "react";
import {Route} from "react-router-dom";
import API from "./api";

// 通过自定义 moneyKeyboardWrapProps 修复虚拟键盘滚动穿透问题
// https://github.com/ant-design/ant-design-mobile/issues/307
// https://github.com/ant-design/ant-design-mobile/issues/163
const isIPhone = new RegExp('\\biPhone\\b|\\biPod\\b', 'i').test(window.navigator.userAgent);
let moneyKeyboardWrapProps;
if (isIPhone) {
  moneyKeyboardWrapProps = {
    onTouchStart: e => e.preventDefault(),
  };
}

const nowTimeStamp = Date.now();
// const now = new Date(nowTimeStamp);
const yesterday = new Date(nowTimeStamp);
yesterday.setDate(yesterday.getDate() - 1);

class Channel extends React.Component {
  state = {
    date: yesterday,
    agent: 'none',
    region: 'AR',
    product: 'toya and',
    channel: 'Facebook',
    expenditure: '0.00',
    recovery: '0.00',
    remarks: '',
    expenditureHasError: false,
    recoveryHasError: false,
    list: [],
    showAgent: false,
    myRegionsProducts: [],
    agents: [],
    channels:[]
  }

  api = new API();

  componentDidMount() {
    const token = localStorage.getItem("token");
    this.api.findChannelRecovery(token).then(r2 => {
      if (r2.code === 200) {
        this.setState({
          list: r2.data,
        })
      }
    });

    const response = this.api.validateAuth(token);
    console.log(response)
    if (response.code === 200) {
      if (response.data.role >= 6) {
        this.setState({
          showAgent: true,
        })
      }
    }

    this.api.getAllAgents(token).then(r => {
      const agents = r.data.agent
      // console.log('agents', agents)
      this.setState({
        agents: agents
      }, /*() => console.log(this.state.agents)*/)
    })

    this.api.getAllRegionProducts(token).then(r => {
      const regionProducts = r.data.region_product
      this.setState({
        myRegionsProducts: regionProducts
      }, /*() => console.log(this.state.myRegionsProducts)*/)
    })

    this.api.getAllChannels(token).then(r => {
      console.log('data',r.data)
      const channels = r.data.channel
      this.setState({
        channels: channels
      }, () => console.log('channels',this.state.channels))
    })
  }


  onExpenditureErrorClick = () => {
    if (this.state.expenditureHasError) {
      Toast.info('请输入花费');
    }
  }

  onRecoveryErrorClick = () => {
    if (this.state.recoveryHasError) {
      Toast.info('请输入回收');
    }
  }


  onExpenditureChange = (expenditure) => {
    if (expenditure.replace(/\s/g, '').length < 1) {
      this.setState({
        expenditureHasError: true,
      });
    } else {
      this.setState({
        expenditureHasError: false,
      });
    }
    this.setState({
      expenditure,
    });
  }

  onRecoveryChange = (recovery) => {
    if (recovery.replace(/\s/g, '').length < 1) {
      this.setState({
        recoveryHasError: true,
      });
    } else {
      this.setState({
        recoveryHasError: false,
      });
    }
    this.setState({
      recovery,
    });
  }

  onClick = (history) => {
    this.onExpenditureChange(this.state.expenditure);
    this.onRecoveryChange(this.state.recovery);
    if (this.state.expenditureHasError || this.state.recoveryHasError) {
      Toast.info('请检查输入错误');
      return;
    }

    const token = localStorage.getItem("token");
    if (!token) {
      history.push('/login');
      return;
    }

    const data = {
      date: Math.floor(this.state.date.getTime() / 1000),
      agent: this.state.agent,
      region: this.state.region,
      product: this.state.product,
      channel: this.state.channel,
      expenditure: this.state.expenditure,
      recovery: this.state.recovery,
      remarks: this.state.remarks,
    }

    this.api.addChannelRecovery(token, data).then(response => {
      if (response.code === 200) {
        Toast.info('已成功提交，请选择新的日期以提交新的记录，否则将覆盖之前的数据');
        this.api.findChannelRecovery(token).then(r2 => {
          if (r2.code === 200) {
            this.setState({
              list: r2.data,
              date: yesterday,
              remarks: '',
              expenditureHasError: false,
              recoveryHasError: false,
            })
          } else if (r2.code === 403001) {
            Toast.info('没有权限做此操作，请联系管理员开通权限');
            this.setState({
              agent: 'none',
              showAgent: false,
              date: yesterday,
              remarks: '',
              expenditureHasError: false,
              recoveryHasError: false,
            });
          } else {
            this.setState({
              date: yesterday,
              remarks: '',
              expenditureHasError: false,
              recoveryHasError: false,
            });
          }
        });
      } else if (response.code === 401000) {
        localStorage.removeItem("token");
        history.push('/login');
        return;
      } else if (response.code === 400205) {
        Toast.info('金额格式错误');
      } else {
        const msg = `系统错误: ${response.code}: ${response.debugMessage}`
        Toast.info(msg);
      }
    });
  }

  render() {
    const {getFieldProps} = this.props.form;
    const rows = [];
    for (let e of this.state.list) {
      const subLabel = `${e.region} ${e.channel} ${e.product}`;
      const expenditureLabel = `花费: ${e.expenditure} $`;
      const recoveryLabel = `回收: ${e.recovery} $`;
      rows.push(<WingBlank size="lg">
        <WhiteSpace size="lg"/>
        <Card>
          <Card.Header
            title={e.statsTime}
            extra={<span>{subLabel}</span>}
          />
          <Card.Footer content={expenditureLabel} extra={<div>{recoveryLabel}</div>}/>

          <Card.Body>
            {
              'none' === e.agent ? <div></div> : <div>代理: <pre>{e.agent}</pre></div>
            }
            <div>备注: <pre>{e.remarks}</pre></div>
          </Card.Body>
        </Card>
        <WhiteSpace size="lg"/>
      </WingBlank>);
    }
    return (
      <div>
        <div style={{
          width: '100%',
          'font-size': '1.5em',
          'margin-top': '2rem',
          'margin-bottom': '2rem',
          'margin-left': '1rem',
          textAlign: 'left'
        }}>渠道
        </div>
        <List>
          {
            this.state.showAgent ? <Picker
              data={this.state.agents}
              cols={1}
              {...getFieldProps('agent', {
                initialValue: ['none'],
              })}
              onOk={e => {
                this.setState({
                  agent: e[0],
                });
              }}
              className="forss">
              <List.Item arrow="horizontal">代理</List.Item>
            </Picker> : <div></div>
          }

          <DatePicker
            mode="date"
            title="日期"
            value={this.state.date}
            extra="Optional"
            onChange={(date) => {
              this.setState({date});
            }}>
            <List.Item arrow="horizontal">日期</List.Item>
          </DatePicker>

          <Picker
            extra="请选择"
            data={this.state.myRegionsProducts}
            title="地区产品"
            {...getFieldProps('regionProducts', {
              initialValue: ['AR', 'toya and'],
            })}
            onOk={e => {
              this.setState({
                region: e[0],
                product: e[1],
              });
            }}
            onDismiss={e => console.log('dismiss', e)}
          >
            <List.Item arrow="horizontal">地区产品</List.Item>
          </Picker>


          <Picker
            data={this.state.channels}
            cols={1}
            {...getFieldProps('channel', {
              initialValue: ['Facebook'],
            })}
            onOk={e => {
              this.setState({
                channel: e[0],
              });
            }}
            className="forss">
            <List.Item arrow="horizontal">渠道</List.Item>
          </Picker>
          <InputItem
            type="money"
            placeholder="0.00"
            clear
            extra="$"
            moneyKeyboardAlign="right"
            moneyKeyboardWrapProps={moneyKeyboardWrapProps}
            error={this.state.expenditureHasError}
            onErrorClick={this.onExpenditureErrorClick}
            onChange={this.onExpenditureChange}
            value={this.state.expenditure}
          >花费</InputItem>

          <InputItem
            type="money"
            placeholder="0.00"
            clear
            extra="$"
            moneyKeyboardAlign="right"
            moneyKeyboardWrapProps={moneyKeyboardWrapProps}
            error={this.state.recoveryHasError}
            onErrorClick={this.onRecoveryErrorClick}
            onChange={this.onRecoveryChange}
            value={this.state.recovery}
          >回收</InputItem>

          <List.Item>
            <div>备注</div>
            <TextareaItem
              {...getFieldProps('remarks', {
                initialValue: this.state.remarks,
              })}
              rows={2}
              count={64}
              onChange={(v) => {
                console.log('textarea', v)
                this.setState({
                  remarks: v
                });
              }}
            />
          </List.Item>

          <List.Item>
            <Route render={({history}) => (
              <div
                style={{width: '100%', color: '#108ee9', textAlign: 'center'}}
                onClick={() => {
                  this.onClick(history);
                }}
              >
                确定
              </div>
            )}/>
          </List.Item>
        </List>

        <div>
          {rows}
        </div>
      </div>
    );
  }
}

export default createForm()(Channel);
