import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect
} from "react-router-dom";
import {Toast, Button, Flex} from 'antd-mobile';
import Channel from "./Channel";
import Yizhiliao from "./Yizhiliao";
import Register from "./Register";
import Login from "./Login";
import Stats from "./Stats";
import API from "./api";

const data = [
  {
    value: '1',
    to: '',
    label: '渠道',
  }, {
    value: '2',
    to: 'yizhiliao',
    label: '一直聊',
  }, {
    value: '3',
    to: 'stats',
    label: '统计',
  }, {
    value: '4',
    to: 'register',
    label: '注册',
  }, {
    value: '5',
    to: 'login',
    label: '登录',
  }, {
    value: '6',
    to: 'logout',
    label: '退出',
  },
];

const links = data.map(item => {
  return (
    <Flex.Item>
      <Button>
        <Link to={item.to}>{item.label}</Link>
      </Button>
    </Flex.Item>
  )
})


function App() {
  return (
    <Router>
      <div className="App">
        <header className="App-header">
          <Flex>
            {links}
          </Flex>

          <Switch>
            <PrivateRoute exact path="/">
              <Channel/>
            </PrivateRoute>
            <AdminPrivateRoute path="/yizhiliao">
              <Yizhiliao/>
            </AdminPrivateRoute>
            <AdminPrivateRoute path="/stats">
              <Stats/>
            </AdminPrivateRoute>
            <Route path="/register">
              <Register/>
            </Route>
            <Route path="/login">
              <Login/>
            </Route>
            <PrivateRoute exact path="/logout">
              <Login/>
            </PrivateRoute>
          </Switch>
        </header>
      </div>
    </Router>
  );
}

const api = new API();

function PrivateRoute({children, ...rest}) {
  return (
    <Route {...rest} render={() => {
      if ("/logout" === rest.path) {
        localStorage.removeItem("token");
        return <Redirect to='/login'/>;
      }
      const token = localStorage.getItem("token");
      if (token) {
        const response = api.validateAuth(token);
        if (response.code === 200) {
          return children;
        } else if (response.code === 401000) {
          localStorage.removeItem("token");
          return <Redirect to='/login'/>;
        } else {
          localStorage.removeItem("token");
          const msg = `系统错误: ${response.code}: ${response.debugMessage}`
          Toast.info(msg);
        }
      }
      return <Redirect to='/login'/>;
    }}/>
  )
}

function AdminPrivateRoute({children, ...rest}) {
  return (
    <Route {...rest} render={() => {
      const token = localStorage.getItem("token");
      if (token) {
        const response = api.validateAuth(token);
        if (response.code === 200) {
          if (response.data.role === 9) {
            return children;
          }
          return <Redirect to='/login'/>;
        } else if (response.code === 401000) {
          localStorage.removeItem("token");
          return <Redirect to='/login'/>;
        } else {
          localStorage.removeItem("token");
          const msg = `系统错误: ${response.code}: ${response.debugMessage}`
          Toast.info(msg);
        }
      }
      return <Redirect to='/login'/>;
    }}/>
  )
}

export default App;
