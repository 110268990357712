import {List, InputItem, Toast} from 'antd-mobile';
import {createForm} from 'rc-form';
import * as React from "react";
import {Route} from "react-router-dom";
import md5Hex from "md5-hex";
import API from "./api";

class Login extends React.Component {

  state = {
    usernameHasError: false,
    usernameValue: '',
    passwordHasError: false,
    passwordValue: '',
  }

  api = new API();

  onUsernameErrorClick = () => {
    if (this.state.usernameHasError) {
      Toast.info('请输入至少3个字符');
    }
  }

  onPasswordErrorClick = () => {
    if (this.state.passwordHasError) {
      Toast.info('请输入至少6个字符');
    }
  }

  onUsernameChange = (usernameValue) => {
    if (usernameValue.replace(/\s/g, '').length < 3) {
      this.setState({
        usernameHasError: true,
      });
    } else {
      this.setState({
        usernameHasError: false,
      });
    }
    this.setState({
      usernameValue,
    });
  }

  onPasswordChange = (passwordValue) => {
    const passLen = passwordValue ? passwordValue.length : 0;
    if (passLen < 6) {
      this.setState({
        passwordHasError: true,
      });
    } else {
      this.setState({
        passwordHasError: false,
      });
    }
    this.setState({
      passwordValue,
    });
  }

  onClick = (history) => {
    this.onUsernameChange(this.state.usernameValue);
    this.onPasswordChange(this.state.passwordValue);
    if (this.state.usernameHasError || this.state.passwordHasError) {
      Toast.info('请检查输入错误');
      return;
    }
    const passHash = md5Hex(this.state.passwordValue).toUpperCase();
    const data = {
      username: this.state.usernameValue.trim(),
      password: passHash,
    }
    this.api.login(data).then(response => {
      if (response.code === 200) {
        localStorage.setItem("token", response.data.token);
        history.push('/');
      } else if (response.code === 401003) {
        Toast.info('用户名或密码错误');
        this.usernameInputRef.focus();
      } else {
        const msg = `系统错误: ${response.code}: ${response.debugMessage}`
        Toast.info(msg);
      }
    });
  }

  render() {
    const {getFieldProps} = this.props.form;
    return (
      <div>
        <div style={{
          width: '100%',
          'font-size': '1.5em',
          'margin-top': '2rem',
          'margin-bottom': '2rem',
          'margin-left': '1rem',
          textAlign: 'left'
        }}>登录
        </div>
        <List>
          <InputItem
            {...getFieldProps('username')}
            clear
            placeholder="用户名"
            error={this.state.usernameHasError}
            onErrorClick={this.onUsernameErrorClick}
            onChange={this.onUsernameChange}
            value={this.state.usernameValue}
            ref={el => this.usernameInputRef = el}
          >用户名</InputItem>

          <InputItem
            {...getFieldProps('password')}
            type="password"
            placeholder="****"
            error={this.state.passwordHasError}
            onErrorClick={this.onPasswordErrorClick}
            onChange={this.onPasswordChange}
            value={this.state.passwordValue}
          >密码</InputItem>

          <List.Item>
            <Route render={({history}) => (
              <div
                style={{width: '100%', color: '#108ee9', textAlign: 'center'}}
                onClick={() => {
                  this.onClick(history);
                }}
              >
                确定
              </div>
            )}/>
          </List.Item>
        </List>
      </div>
    );
  }
}

export default createForm()(Login);
